<template>
<div>
  <div class="mt-4">
    <v-btn class="white--text" dense color="#2879FF" @click="openProgressDlg">更新项目进度</v-btn>
  </div>
  <div class="mt-2">
    <v-timeline align-top dense>
      <v-timeline-item color="green" small v-for="p in progresslist">
        <v-row class="pt-0">
          <v-col cols="1">
            <strong>{{p.date.replace(/(.*)\/(.*)\/(.*)/, "$2月 $3日")}}</strong>
          </v-col>
          <v-col>
            <div :class="p.color == 'green' ? 'project-progress ' : 'project-progress-past text-left'">
              <div :class="p.color == 'green' ? 'percent' : ''">{{p.percent}}%</div>
              <div class="mt-0">{{!!p.text?'进度说明:'+p.text:' '}}</div>
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </div>
  <ItemProgressDlg @save="updateProgress" v-bind:newProgress="itemProgressParam" ref="itemProgressDlg" />
</div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  name: 'ItemSchedule',
  props: ['item'],
  components: {
    ItemProgressDlg: () => import('@/components/itemDetail/shared/ItemProgressDlg.vue'),
  },
  data() {
    return {
      dialog: false,
      percent: 0,
      description: '',
      itemProgressParam: {
        pr_parent_type: 3,
        pr_description: '',
        pr_percent: 0
      },
    }
  },
  computed: {
    ...mapGetters('progress', ['itemProgress']),
    progresslist: function () {
      let tmp = [];
      let len = this.itemProgress.length;
      this.itemProgress.map((p, i) => {
        let date = new Date(p.created_at);
        date = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
        tmp.push({
          color: i == 0 ? 'green' : 'grey',
          percent: p.pr_percent,
          text: p.pr_description,
          date: date
        })
      });
      return tmp;
    }
  },
  mounted() {
    this.getProgress({
      pr_parent: this.item.item_id,
      pr_parent_type: 3
    });
  },
  methods: {
    ...mapActions('item', ['updateItemByField']),
    ...mapActions('progress', ['addProgress', 'getProgress']),
    updateProgress() {
      this.$emit('addProgress', this.itemProgressParam)
    },
    openProgressDlg() {
      this.itemProgressParam.pr_percent = this.item.item_progress
      this.itemProgressParam.pr_parent = this.item.item_id
      this.$refs.itemProgressDlg.open()
    },
    updatePage() {
      this.getProgress({
        pr_parent: this.item.item_id,
        pr_parent_type: 3
      });
    },
  }
}
</script>

<style scoped>
.project-progress {
  padding: 20px;
  background-color: #6FD9C7;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.project-progress-past {
  padding: 10px;
  background-color: #F5F8FA;
  color: #70818C;
  border-radius: 10px;
  text-align: center;
}

.percent {
  width: 70px;
  height: 70px;
  background: rgba(256, 256, 256, 0.1);
  margin: 0 auto;
  border-radius: 50%;
  padding-top: 22px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
</style>
